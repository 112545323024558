// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");

function Price(props) {
  var size = props.size;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: TailwindMerge.twMerge([
                    "text-tan-gold",
                    size !== undefined ? (
                        size === "medium" ? "text-2xl font-medium" : (
                            size === "small" ? "text-xl font-semibold" : "text-3xl font-medium"
                          )
                      ) : "text-2xl font-medium sm:text-3xl ",
                    Core__Option.getOr(props.className, "")
                  ])
            });
}

var make = Price;

exports.make = make;
/* tailwind-merge Not a pure module */
